// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'

import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        AdditionalInformation: ?String,
    }
}

function Step1 (props: Props) {
    const { handleFieldChange, values, isLoading } = props
    const { t } = useTranslation(transDomain)

    const handleOnAdditional = React.useCallback(value => {
        return handleFieldChange('AdditionalInformation', value)
    }, [handleFieldChange])

    const phoneField = React.useMemo(() => {
        if (props.values.mobilePhone) {
            return 'mobilePhone'
        }

        if (props.values.homePhone) {
            return 'homePhone'
        }

        for (let field in props.values) {
            if (field.endsWith('Phone') && props.values[field]) {
                return field
            }
        }

        return 'mobilePhone'
    }, [props.values])

    return (
        <div>
            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <div className={styles.title}>
                        <TextBlock
                            blockId={'title.personalInfo'}
                            defaultContent={t('title1')}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1} className={styles.row1}>
                        <Form.Field
                            defaultValue={values.firstName || null}
                            onChange={value => {
                                handleFieldChange('firstName', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.name')}
                                />
                        <Form.Field
                            defaultValue={values.lastName || null}
                            onChange={value => {
                                handleFieldChange('lastName', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.lastname')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row2}>
                        <Form.Field
                            defaultValue={values.addressLine1 || null}
                            onChange={value => {
                                handleFieldChange('addressLine1', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.address')}
                                />
                        <Form.Field
                            defaultValue={values.addressLine2 || null}
                            onChange={value => {
                                handleFieldChange('addressLine2', `${value}`)
                            }}
                            type='number'
                            required={false}
                            placeholder={t('fields.unit')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row3}>
                        <Form.Field
                            defaultValue={values.city || null}
                            onChange={value => {
                                handleFieldChange('city', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.city')}
                        />
                        <Form.Field
                            defaultValue={values.province || null}
                            onChange={value => {
                                handleFieldChange('province', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.state')}
                        />
                        <Form.Field
                            defaultValue={values.postalCode || null}
                            onChange={value => {
                                handleFieldChange('postalCode', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.zipcode')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row4}>
                        <Form.Field
                            defaultValue={values[phoneField] || null}
                            onChange={value => {
                                handleFieldChange(phoneField, `${value}`)
                            }}
                            type='phone'
                            placeholder={t('fields.phone')}
                        />
                        <Form.Field
                            defaultValue={values.email || null}
                            onChange={value => {
                                handleFieldChange('email', `${value}`)
                            }}
                            type='email'
                            placeholder={t('fields.email')}
                        />
                    </Form.RowColumn>

                    <div className={styles.title}>
                        <TextBlock
                            blockId={'title.additionalInfo'}
                            defaultContent={t(props.theme.textAreaLabelTransKey)}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1}>
                        <Form.Textarea
                            defaultValue={values.AdditionalInformation || null}
                            onChange={handleOnAdditional}
                            type='text'
                            required={false}
                            rows={9}
                            placeholder={t('fields.AdditionalInformation')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <div className={styles.center}>
                    <Form.SubmitBlock
                        loading={isLoading}
                        blockId={'leadGenForm.submit'}
                        label={t('fields.continue')}
                    />
                </div>
            </Form.Row>
        </div>
    )
}

Step1.defaultProps = {
    values: {
        anonymousDonation: false
    }
}

export default withForm(true)(withTheme(supportedThemes)(Step1))
