import React from 'react'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'
import { BackgroundImageBlock, ImageBlock, TextBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

import VideoPlayButton from 'components/VideoPlayButton'

import styles from './styles.module.scss'

import propertiesSchema from './properties.schema.json'
import defaultImage from 'components/SideBar/images/bg.png'

const DEFAULT_MESSAGE = `
    <h3>{firstName}, thank you for being a part of their story.</h3>
`

export default function SideBar () {
    const { ref, properties } = useBlock('sidebar', propertiesSchema)
    const { state } = React.useContext(Store)

    return (
        <>
            <div ref={ref} className={styles.sideBarContainer} style={{
                '--background-color': properties.backgroundColor,
            }}>
                <div className={styles.logo}>
                    <ImageBlock
                        blockId="sidebar.logo"
                        defaultImage="$.logo.regular"
                        resize="both"
                        maxWidth="100%"
                        title="Logo"
                    />
                </div>

                <div className={styles.message}>
                    <TextBlock
                        style={{
                            '--heading-color': properties.headingColor,
                            '--color': properties.color,
                        }}
                        blockId="sidebar.message"
                        values={{ ...state.lead.fields }}
                        valuesSchema={state.campaign.mergeTags}
                        locale={state.locale}
                        defaultContent={DEFAULT_MESSAGE}
                        onAnchorClick={createClickActivity}
                    />
                </div>

                <div className={styles.imageWrapper}>
                    <BackgroundImageBlock
                        className={styles.image}
                        blockId="sidebar.image"
                        defaultImage={defaultImage}
                        title="Sidebar image"
                    />

                    <VideoPlayButton/>
                </div>
            </div>
        </>
    )
}
