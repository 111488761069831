// @flow
import * as React from 'react'
import { ImageBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

import FooterBar from 'components/FooterBar'

import styles from './styles.module.scss'
import propertiesSchema from './properties.schema.json'

let DEFAULT_PROPERTIES = {}

export default function Footer () {
    const { properties, ref } = useBlock('footer', propertiesSchema, DEFAULT_PROPERTIES)

    return (
        <div ref={ref} className={styles.container} style={{
            backgroundColor: properties.backgroundColor,
        }}>
            <div className={styles.logo}>
                <ImageBlock
                    blockId="footer.logo"
                    defaultImage="$.logo.inverted"
                    resize="both"
                    title="Footer logo"
                />
            </div>

            <FooterBar/>
        </div>
    )
}
