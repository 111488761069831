import React from 'react'
import { updateLead } from 'Actions'
import { useTranslation } from 'react-i18next'
import { useBlock, TextBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'

import Footer from 'components/Footer'
import SideBar from 'components/SideBar'
import Step1 from 'components/LeadGenForm/Step1'

import supportedThemes from './themes/__supportedThemes'

import transDomain from './translations/index.translations'
import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'
import withTheme from '../../hoc/withTheme'

export default withTheme(supportedThemes)(function Landing (props) {
    const { state, dispatch } = React.useContext(Store)
    const { ref, properties } = useBlock('landing.rightSide', propertiesSchema)
    const { t } = useTranslation(transDomain)
    const firstName = state.lead.fields.firstName
    const isConquest = !state.lead.fields.firstName || state.lead.fields.firstName === ''

    const initialValues = React.useMemo(() => {
        return state.lead.fields
    }, [state.lead.fields])

    return (
        <>
            <div className={styles.grid} style={{ '--primary-color': props.theme.primaryColor }}>
                <div className={styles.leftSide}>
                    <SideBar name={firstName}/>
                </div>

                <div className={styles.rightSide}>
                    <div ref={ref} style={{ backgroundColor: properties.backgroundColor }}>
                        <div className={styles.headerInfo}>
                            {isConquest
                                ? <h4>
                                    <TextBlock
                                        className={styles.title}
                                        blockId="masthead.headingEmpty"
                                        defaultContent={t('masthead.headingEmpty')}
                                        values={{ ...state.lead.fields }}
                                        valuesSchema={state.campaign.mergeTags}
                                        locale={state.locale}
                                        inline
                                        onAnchorClick={createClickActivity}
                                    />
                                </h4>
                                : <h4>
                                    <TextBlock
                                        className={styles.title}
                                        blockId="masthead.heading"
                                        defaultContent={t('masthead.heading')}
                                        values={{ ...state.lead.fields }}
                                        valuesSchema={state.campaign.mergeTags}
                                        locale={state.locale}
                                        inline
                                        onAnchorClick={createClickActivity}
                                    />
                                </h4>
                            }
                            <TextBlock
                                className={styles.subtitle}
                                blockId="masthead.message"
                                defaultContent={t('title')}
                                values={{ ...state.lead.fields }}
                                valuesSchema={state.campaign.mergeTags}
                                locale={state.locale}
                                onAnchorClick={createClickActivity}
                            />
                        </div>
                        <Step1
                            onSubmit={async (data, done) => {
                                try {
                                    let lead = {
                                        fields: data,
                                        isComplete: true
                                    }
                                    await updateLead(dispatch, state.code, lead)
                                    done()
                                    window.scrollTo(0, 0)
                                } catch (err) {
                                    alert('Oops something went wrong')
                                    console.error(err)
                                    done()
                                }
                            }}
                            initialValues={initialValues}
                        />
                    </div>
                    <span><Footer /></span>
                </div>
            </div>
        </>
    )
})
